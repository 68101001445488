import { Grid } from '@cointracker/styleguide/src/Grid';
import { Body1, Heading } from '@cointracker/styleguide/src/LoggedIn';
import classNames from 'classnames';
import { useRef } from 'react';
import { Carousel } from '../Carousel';
import { usePushFade } from '../hooks/usePushFade';
import { Body2, Body3 } from '../typography';
import { boxShadow, socialContainerOuter } from './SocialTestimonialBlock.css';
import { container, topCopy } from './TestimonialBlock.css';

export type SocialTestimonial = {
  body: string;
  name: string;
  date: string;
  socialIconSrc: string;
  socialIconAlt: string;
  profileSrc: string;
};

interface SocialTestimonialBlockProps {
  alignLeft?: boolean;
  animated?: boolean;
  subtext: string;
  testimonials: SocialTestimonial[];
  title: string;
  fullscreen?: boolean;
}

export const SocialTestimonialBlock = ({
  alignLeft = false,
  animated,
  subtext,
  testimonials,
  title,
  fullscreen,
}: SocialTestimonialBlockProps) => {
  const ref = useRef<HTMLDivElement>(null);

  usePushFade(ref.current, { disabled: !animated });

  return (
    <div className={socialContainerOuter} ref={ref}>
      <div className={classNames(container({ fullscreen }))}>
        <Grid className={topCopy({ alignLeft })}>
          <Heading variant="h3" as="h2">
            {title}
          </Heading>
          <Body1 className="text-text-secondary">{subtext}</Body1>
        </Grid>
        <div data-push-fade-item className="w-full">
          <Carousel>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={classNames(
                  'rounded-16 bg-background-card-surface flex h-[363px] min-w-[360px] flex-col justify-between p-16 max-md:min-w-[275px] max-md:max-w-[275px] lg:pt-24',
                  boxShadow,
                )}
              >
                <Body1>{testimonial.body}</Body1>
                <div className="flex w-full items-center gap-12">
                  <img
                    src={testimonial.profileSrc}
                    alt={testimonial.name}
                    className="h-48 w-48"
                  />
                  <div className="flex flex-col gap-2">
                    <Body2>{testimonial.name}</Body2>
                    <Body3 variant="secondary">{testimonial.date}</Body3>
                  </div>
                  {testimonial.socialIconSrc && (
                    <div className="flex grow justify-end pr-4">
                      <img
                        src={testimonial.socialIconSrc}
                        alt={testimonial.socialIconAlt}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
